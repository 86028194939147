/* Basscss Utility Headings */

.h00 {
  font-size: $h00;
}

.h0 {
  font-size: $h0;
}

@media #{$breakpoint-md} {
  .h00-responsive {
    font-size: $h00-responsive;
  }

  .h0-responsive {
    font-size: $h0-responsive;
  }

  .h1-responsive {
    font-size: $h1-responsive;
  }
}

@media #{$breakpoint-xl} {
  .h00-responsive {
    font-size: $h00-responsive-max;
  }

  .h0-responsive {
    font-size: $h0-responsive-max;
  }

  .h1-responsive {
    font-size: $h1-responsive-max;
  }
}
