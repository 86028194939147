/* Basscss Base Forms */

input,
select,
textarea,
fieldset {
  font-size: $form-field-font-size;
  margin-top: 0;
  margin-bottom: $space-1;
}

input[type=text],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week] {
  box-sizing: border-box;
  height: $form-field-height;
  padding: $form-field-padding-y $form-field-padding-x;
  vertical-align: middle;
  -webkit-appearance: none;
}

select {
  box-sizing: border-box;
  line-height: 1.75;
  padding: $form-field-padding-y $form-field-padding-x;
}

select:not([multiple]) {
  height: $form-field-height;
  vertical-align: middle;
}

textarea {
  box-sizing: border-box;
  line-height: 1.75;
  padding: $form-field-padding-y $form-field-padding-x;
}

.fieldset-reset {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  border: 0;
}

.fieldset-reset legend {
  padding: 0;
}
