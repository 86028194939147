@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@media screen and (min-width: $viewport-small) {
    html {
        font-size: 16px;
    }

  h1,
  .h1 {
    font-size: $h1;
  }

  .site-header {
    text-align: left;
  }

  .site-nav {
    margin-top: 0;
  }

  .site-header a {
    font-size: $h4;
  }

  .site-header .site-title {
    font-size: $h3;
    float: left;
  }

  .site-header .site-nav {
    float: right;
  }

  blockquote {
    margin-left: $space-3;
    padding: $space-3;
  }

  .social-icons-left {
    text-align: left;
  }

  .social-icons-right {
    text-align: right;
  }
  .img_row {
     height: $img-height/3;
  }
  .col {
      padding: $img-spacing/3;
  }
}

@media screen and (min-width: $viewport-medium) {
  html {
    font-size: 18px;
  }
  .img_row {
     height: $img-height/1.5;
  }
  .col {
      padding: $img-spacing/1.5;
  }
}

@media screen and (min-width: $viewport-large) {
  html {
    font-size: 20px;
  }
  .img_row {
     height: $img-height;
  }
  .col {
      padding: $img-spacing;
  }
}

@media screen and (min-width: $viewport-large + 14) {
  em img {
    max-width: $measure-width + 14;
    margin-left: -7em;
  }
}
