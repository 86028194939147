/* Basscss Base Buttons */

button,
.button {
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  line-height: $button-line-height;
  padding: $button-padding-y $button-padding-x;
  margin: 0;
  height: auto;
  border: 1px solid transparent;
  vertical-align: middle;
  -webkit-appearance: none;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:hover {
  text-decoration: none;
}
