/* Basscss Utility Typography */

.bold {
  font-weight: $bold-font-weight;
}

.regular {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.caps {
  text-transform: uppercase;
  letter-spacing: .2em;
}

.left-align {
  text-align: left;
}

.center {
  text-align: center;
}

.right-align {
  text-align: right;
}

.justify {
  text-align: justify;
}

.nowrap {
  white-space: nowrap;
}
