/* Basscss Colors */

.dark-gray {
  color: $dark-gray;
}

.white {
  color: white;
}

.blue {
  color: $blue;
}

.mid-gray {
  color: $mid-gray;
}

.light-gray {
  color: $light-gray;
}

.lighter-gray {
  color: $lighter-gray;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.orange {
  color: $orange;
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-white {
  background-color: white;
}

.bg-blue {
  background-color: $blue;
}

.bg-mid-gray {
  background-color: $mid-gray;
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-lighter-gray {
  background-color: $lighter-gray;
}

.bg-red {
  background-color: $red;
}

.bg-green {
  background-color: $green;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-orange {
  background-color: $orange;
}

.bg-darken-1 {
  background-color: $darken-1;
}

.bg-darken-2 {
  background-color: $darken-2;
}

.bg-darken-3 {
  background-color: $darken-3;
}

.bg-darken-4 {
  background-color: $darken-4;
}
